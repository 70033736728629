.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semitransparente */
    z-index: 1000; /* Garanta que o alerta esteja acima de outros elementos */
  }
  
  .alert-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .alert-box p {
    margin: 0 0 10px 0;
  }
  
  .alert-box button {
    margin-top: 10px;
  }
  