.contas-container {
  min-height: 100vh;
  /*   background-image: url("../../assets/fundo.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat; */
  background-color: #ccc4c4;
  display: flex;

}

.contas-form {
  max-width: 600px;
  padding: 10px;
  border-radius: 5px;
  /* Rounded corners */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  background-color: #000000;
  /* Light background */
}

.wide-card-body {
  width: 400px;
  /* Altere este valor conforme necessário */
}

.form-group {
  margin-bottom: 1rem;
}

label {
  color: #ffffff;
  /* Cor personalizada */
  font-weight: bold;
}

input[type="email"],
input[type="password"] {
  height: 50px;
  border: 1px solid #ccc;
  /* Light border color */
  border-radius: 4px;
  /* Rounded corners for inputs */
  padding: 10px;
}

.btn-primary {
  background-color: #38a3a5;
  /* Custom blue color */
  border-color: #38a3a5;
  /* Match button color */
  transition: all 0.3s ease-in-out;
  /* Smooth hover effect */
}

.btn-primary:hover {
  background-color: #2e8c8d;
  /* Darken on hover */
  border-color: #2e8c8d;
}

.features {
  display: flex;
  justify-content: space-between;

}
.feature {
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}



.navbar {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #121212;
}

.navbar button {
  position: relative;
  display: grid;
  place-items: center;
  width: 40px;
  height: 72px;
  border: 0;
  padding: 0;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
}

.navbar .burger {
  width: 72px;
}