* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: #23242a;
  height: 100vh;
  width: 100vw;
}

.box {
  position: relative;
  width: 370px;
  height: 450px;
  background: #1c1c1c;
  border-radius: 50px 5px;
  overflow: hidden;
}

.box::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 370px;
  height: 450px;
  background: linear-gradient(60deg, transparent, #45f3ff, #45f3ff);
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
}

.box::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 370px;
  height: 450px;
  background: linear-gradient(60deg, transparent, #d9138a, #d9138a);
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
  animation-delay: -3s;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

form {
  position: absolute;
  inset: 2px;
  background: #28292d;
  border-radius: 50px 5px;
  z-index: 10;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
}


.title {
  width: 100%;
   display: flex;     /* Ativa o flexbox */
  justify-content: center; /* Centraliza horizontalmente */
}

.title h1 {
  color: #45f3ff;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  opacity: 0.9;
  margin-bottom: 20px;
}

form .input-box {
  width: 100%;
  margin-top: 20px;
}

form .input-box input {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  outline: none;
  border-width: 3px;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  margin: 10px 0px 10px 0px;
  color: white;
}


form .input-box button {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  outline: none;
  border-width: 3px;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  margin: 10px 0px 10px 0px;
  color: white;
}

form .input-box input::placeholder {
  color: #cdd1d2;
}

form .input-box button::placeholder {
  color: #cdd1d2;
}

form .input-box input[type="submit"] {
  background-color: #45f3ff;
  cursor: pointer;
  color: #16100e;
  filter: drop-shadow(0 5px 10px #45f3ff);
  margin-bottom: 20px;
}

form .input-box button[type="submit"] {
  background-color: #45f3ff;
  cursor: pointer;
  color: #16100e;
  filter: drop-shadow(0 5px 10px #45f3ff);
  margin-bottom: 20px;
}


form .input-box button[type="submit"]:hover {
  background-color: #45f3ff; /* Cor de fundo mais clara ou diferente */
  transform: scale(1.05);   /* Aumenta o tamanho levemente */
  box-shadow: 0 8px 16px rgba(69, 243, 255, 0.5); /* Sombra mais intensa */
  justify-content: center;
}


form .link-text {
  padding-top: 15px;
  color: rgb(103, 173, 183);
  font-size: 0.85rem;
}

form .link-text a {
  text-decoration: none;
  color: rgb(153, 41, 99);
  font-weight: 700;
}

.label-color {
  color: #9eb3b5;
}